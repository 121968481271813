// ** React Imports
import { createContext, useEffect, useState, ReactNode, useCallback } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Types
import { AuthValuesType, UserType } from './types'

import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import store, { AppDispatch } from '../store'
import { AuthState, deleteCredentials, fetchCurrentProfile, setAuthLoading } from '../store/slices/auth'
import { deleteCookie, getCookie } from 'cookies-next'

import authConfig from '../configs/auth'
import { RequestMethod } from '../types/enums/RequestMethod'
import { deletePermissions, fetchCurrentRolePermissions } from '../store/slices/permissions'
import apiClient from '../configs/axiosConfig'

// ** Defaults
const defaultProvider: AuthValuesType = {
    user: store.getState().auth.user ?? null,
    loading: store.getState().auth.loading ?? true,
    setUser: () => null,
    setLoading: () => Boolean,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

type Props = {
    children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
    // ** States
    const [user, setUser] = useState<Omit<UserType, 'created_at' | 'updated_at' | 'last_login_at' | 'email_verified_at'> | null>(defaultProvider.user)
    const [loading, setLoading] = useState<boolean>(defaultProvider.loading)
    const dispatch = useDispatch<AppDispatch>()
    const currentUser = useSelector((state: { auth: AuthState }) => state.auth.user, shallowEqual)

    const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL
    const deleteAllStoredData = useCallback(() => {
        store.dispatch(deleteCredentials())
        store.dispatch(deletePermissions())
        deleteCookie('XSRF-TOKEN')
    }, [])

    // ** Hooks

    useEffect(() => {
        const token = getCookie('XSRF-TOKEN', { secure: true, httpOnly: true })
        if (!currentUser || !token) {
            deleteAllStoredData()
            setLoading(false)
        } else {
            if (currentUser && currentUser.role_id) {
                dispatch(setAuthLoading(false))
                ;(async () => {
                    await dispatch(fetchCurrentRolePermissions(currentUser.role_id))
                    await dispatch(fetchCurrentProfile())
                })()
                setLoading(false)
            } else {
                dispatch(setAuthLoading(false))
                deleteAllStoredData()
                setLoading(false)
            }
        }
    }, [])

    const router = useRouter()
    const handleLogin = async () => {
        console.log('testLogin')
    }

    //TODO: refactor handleLogout
    const handleLogout = async () => {
        apiClient.defaults.withCredentials = true
        try {
            const LogoutResponse = await apiClient(BASE_URL + authConfig.logout, {
                method: RequestMethod.POST,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            if (LogoutResponse) {
                deleteAllStoredData()
                setLoading(false)
                delete apiClient.defaults.headers.common['Authorization']

                return LogoutResponse.data
            }
        } catch (e: any) {
            console.log(e, 'LogoutError')
            switch (e.response?.status) {
                case 401:
                    deleteAllStoredData()
                    setLoading(false)
                    break
                case 419:
                    deleteAllStoredData()
                    setLoading(false)
                    //await router.replace('/')
                    break
                default: {
                    setLoading(false)
                    console.log(e)
                    break
                }
            }
        }
    }

    const values = {
        user,
        loading,
        setUser,
        setLoading,
        login: handleLogin,
        logout: handleLogout
    }

    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
