/**
 *  Set Home URL based on User Roles
 */

const getHomeRoute = (role: number) => {
    if (!role) return '/401'
    else return '/home'
}

export default getHomeRoute
