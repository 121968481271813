// ** Redux Imports
import { createSlice, createAsyncThunk, Draft, PayloadAction } from '@reduxjs/toolkit'

// ** Axios Imports

import RolesEndpoint from '../../../configs/roles'

import { RequestMethod } from '../../../types/enums/RequestMethod'
import toast from 'react-hot-toast'
import { PermissionRoleType } from '../../../types/apps/rolesTypes'
import { deleteCredentials } from '../auth'
import store from '../../index'
import { deleteCookie } from 'cookies-next'
import apiClient from '../../../configs/axiosConfig'

const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL

export interface PermissionsState {
    data: { [module: string]: string[] } | []
    isLoading: boolean
}

const initialState: PermissionsState = {
    data: [],
    isLoading: false
}

// ** Fetch Invoices
export const fetchCurrentRolePermissions = createAsyncThunk('permissions/fetchCurrentRolePermissions', async (role_id: number) => {
    apiClient.defaults.withCredentials = true
    try {
        const response = await apiClient(BASE_URL + RolesEndpoint(role_id).show, {
            method: RequestMethod.GET,
            headers: {
                Accept: 'application/json'
            }
        })
        const data = response.data.data.modules_permissions
        if (data) {
            const transformedRoles: { [module: string]: string[] } = {}

            data.forEach((item: PermissionRoleType) => {
                const { module_name, module_active, data_create, data_view, data_delete, data_edit } = item
                const permissions: string[] = []
                if (module_active) {
                    if (module_name) if (data_create) permissions.push('data_create')
                    if (data_view) permissions.push('data_view')
                    if (data_delete) permissions.push('data_delete')
                    if (data_edit) permissions.push('data_edit')
                    transformedRoles[module_name] = permissions
                }
            })

            return transformedRoles
        }
    } catch (e: any) {
        toast.error(e?.message)
        console.log(e, 'Error fetching permission')
        if (e.response?.status) {
            switch (e.response.status) {
                case 401:
                    deleteCookie('XSRF-TOKEN')
                    store.dispatch(deleteCredentials())
                    store.dispatch(deletePermissions())
                    break
                case 419:
                    deleteCookie('XSRF-TOKEN')
                    store.dispatch(deleteCredentials())
                    store.dispatch(deletePermissions())
                    break
                default:
                    console.log(e, 'Error fetching permission')
                    break
            }
        }
    }
})

export const appPermissionsSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        deletePermissions: (state: Draft<PermissionsState>) => {
            state.data = []
            state.isLoading = false
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCurrentRolePermissions.fulfilled, (state: Draft<PermissionsState>, action: PayloadAction<any>) => {
            state.data = action.payload
            state.isLoading = false
        })
        builder.addCase(fetchCurrentRolePermissions.pending, state => {
            state.isLoading = true
        })
        builder.addCase(fetchCurrentRolePermissions.rejected, state => {
            state.data = []
            state.isLoading = false
        })
    }
})
export const { deletePermissions } = appPermissionsSlice.actions

export default appPermissionsSlice.reducer
