// ** React Imports
import { ReactNode, ReactElement, useEffect } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Hooks Import
import { shallowEqual, useSelector } from 'react-redux'
import { AuthState } from '../../../store/slices/auth'

interface GuestGuardProps {
    children: ReactNode
    fallback: ReactElement | null
}

const GuestGuard = (props: GuestGuardProps) => {
    const { children, fallback } = props
    const router = useRouter()
    const user = useSelector((state: { auth: AuthState }) => state.auth.user, shallowEqual)
    const loading = useSelector((state: { auth: AuthState }) => state.auth.loading, shallowEqual)

    useEffect(() => {
        if (!router.isReady) {
            return
        }

        if (user) {
            ;(async () => {
                if (!router.pathname.includes('reset-password')) {
                    await router.replace('/').then(finished => {
                        finished && console.log('Guestguard:finished replace /')
                    })
                }
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.route])

    if (loading || (!loading && user !== null)) {
        return fallback
    }

    return <>{children}</>
}

export default GuestGuard
