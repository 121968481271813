// Import the functions you need from the SDKs you need

import { useEffect, useRef } from 'react'
import { initializeApp } from 'firebase/app'
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics'
import { firebaseConfig } from '../configs/firebaseConfig'

export default function useFirebase() {
    const analytics = useRef<any>()
    const initialize = () => {
        try {
            //Inizializzazione di firebase
            const app = initializeApp(firebaseConfig)

            //Ottenimento di analytics da firebase
            const analyticsInstance = getAnalytics(app)
            if (analyticsInstance) {
                analytics.current = analyticsInstance
                setAnalyticsCollectionEnabled(analyticsInstance, true)
            }
        } catch (e: any) {
            console.log('Error inizializeWebFirebaseServices', e)
        }
    }

    useEffect(() => {
        initialize()
    }, [])

    return { analytics }
}
