import { RoleType } from 'src/types/apps/rolesTypes'

const RolesEndpoint = (id?: RoleType['id']) => {
    const BASE = 'api/backoffice/roles/'

    return {
        list: BASE,
        create: BASE,
        delete: `${BASE}${id?.toString()}`,
        edit: `${BASE}${id?.toString()}`,
        show: `${BASE}${id?.toString()}`,
        list_all: `${BASE}` + 'all'
    }
}
export default RolesEndpoint
