import { createAsyncThunk, createSlice, Draft } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserType } from '../../../context/types'

import { RequestMethod } from '../../../types/enums/RequestMethod'
import toast from 'react-hot-toast'
import { deleteCookie } from 'cookies-next'
import store from '../../index'
import { deletePermissions } from '../permissions'

import ProfileEndpoint from '../../../configs/profile'
import apiClient from '../../../configs/axiosConfig'

const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL

export type AuthState = {
    user: Omit<UserType, 'created_at' | 'updated_at' | 'last_login_at' | 'email_verified_at'> | null
    loading: boolean
}
export const fetchCurrentProfile = createAsyncThunk('auth/fetchCurrentProfile', async () => {
    apiClient.defaults.withCredentials = true

    try {
        const response = await apiClient(BASE_URL + ProfileEndpoint().show, {
            method: RequestMethod.GET,
            headers: {
                Accept: 'application/json'
            }
        })
        const data = response.data.data

        return data
    } catch (e: any) {
        toast.error(e?.message)
        if (e.response.status === 401) {
            deleteCookie('XSRF-TOKEN')
            store.dispatch(deleteCredentials())
            store.dispatch(deletePermissions())
        }

        console.log(e, 'Error fetching profile')
    }
})
const slice = createSlice({
    name: 'auth',
    initialState: { user: null, token: null, loading: false } as AuthState,
    reducers: {
        setCredentials: (
            state,
            action: PayloadAction<{
                user: Omit<UserType, 'created_at' | 'updated_at' | 'last_login_at' | 'email_verified_at'>
            }>
        ) => {
            state.user = action.payload.user
            state.loading = false
        },
        deleteCredentials: state => {
            state.user = null
            state.loading = false
        },
        setAuthLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCurrentProfile.fulfilled, (state: Draft<AuthState>, action: PayloadAction<any>) => {
            state.user = {
                active: state.user?.active as boolean,
                email: action.payload?.email,
                full_name: action.payload?.full_name,
                first_name: action.payload?.first_name,
                last_name: action.payload?.last_name,
                id: action.payload?.id,
                role_id: action.payload?.role_id,
                role_name: action.payload?.role_name
            }
            //state.loading = false
        })
        builder.addCase(fetchCurrentProfile.pending, state => {
            //state.loading = true
        })
        builder.addCase(fetchCurrentProfile.rejected, state => {
            //state.loading = false
        })
    }
})

export const { setCredentials, deleteCredentials, setAuthLoading } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: AuthState) => {
    return state.user
}

export const selectAuthLoading = (state: AuthState) => {
    state.loading
}
