import { UserType } from '../context/types'

const ProfileEndpoint = (id?: UserType['id']) => {
    const BASE = 'api/backoffice/profile/'

    return {
        edit: BASE,
        show: BASE
    }
}
export default ProfileEndpoint
