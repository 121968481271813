// ** MUI Imports
import { useTheme } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import useSvgLogo from '../../../hooks/useSvgLogo'

const FallbackSpinner = ({ sx }: { sx?: BoxProps['sx'] }) => {
    // ** Hook
    const theme = useTheme()
    const { svgIconLogo } = useSvgLogo()
    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                ...sx
            }}
        >
            {svgIconLogo}
            <CircularProgress disableShrink sx={{ mt: 6 }} />
        </Box>
    )
}

export default FallbackSpinner
