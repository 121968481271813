// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import dynamic from 'next/dynamic'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import React, { useEffect, useMemo, useState } from 'react'
import Chip from '@mui/material/Chip'
import { useRouter } from 'next/router'

interface Props {
    hidden: boolean
    settings: Settings
    toggleNavVisibility: () => void
    saveSettings: (values: Settings) => void
}

const AppBarContent = (props: Props) => {
    // ** Components
    const UserDropdown = dynamic(() => import('src/@core/layouts/components/shared-components/UserDropdown'))
    const ModeToggler = dynamic(() => import('src/@core/layouts/components/shared-components/ModeToggler'))
    const LanguageDropdown = dynamic(() => import('src/@core/layouts/components/shared-components/LanguageDropdown'))

    // ** Props
    const { hidden, settings, saveSettings, toggleNavVisibility } = props
    const env = process.env.NEXT_PUBLIC_API_BASE_URL
    const isStageEnvironment = useMemo(() => {
        if (env) {
            return env.includes('backend.stage')
        }
    }, [env])
    const router = useRouter()
    const [isPwa, setIsPWA] = useState<boolean>(false)

    useEffect(() => {
        if (window.matchMedia('(display-mode: standalone)').matches) setIsPWA(true)
    }, [])

    return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                {hidden ? (
                    <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
                        <Icon icon='mdi:menu' />
                    </IconButton>
                ) : null}
            </Box>
            <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
                {/*
                <LanguageDropdown settings={settings} saveSettings={saveSettings} />
*/}
                {/*   {hidden ? (
                    <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
                        <Icon icon='mdi:menu' />
                    </IconButton>
                ) : null}*/}
                {isStageEnvironment && <Chip color={'warning'} variant={'filled'} label={'Development'} />}
                {isPwa && (
                    <IconButton
                        color='inherit'
                        onClick={() => {
                            router.reload()
                        }}
                    >
                        <Icon icon='mdi:reload' />
                    </IconButton>
                )}
                <ModeToggler settings={settings} saveSettings={saveSettings} />
                <UserDropdown settings={settings} />
            </Box>
        </Box>
    )
}

export default AppBarContent
