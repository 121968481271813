// ** React Imports
import { ElementType, useMemo } from 'react'

// ** Next Imports
import Link from 'next/link'
import { useRouter } from 'next/router'

// ** MUI Imports
import Chip from '@mui/material/Chip'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton'

// ** Configs Import
import themeConfig from 'src/configs/themeConfig'

// ** Types
import { NavLink, NavGroup } from 'src/@core/layouts/types'
import { Settings } from 'src/@core/context/settingsContext'

// ** Custom Components Imports
import UserIcon from 'src/layouts/components/UserIcon'
import Translations from 'src/layouts/components/Translations'
import CanViewNavLink from 'src/layouts/components/acl/CanViewNavLink'

// ** Util Import
import { handleURLQueries } from 'src/@core/layouts/utils'
import { useSearchParams } from 'next/navigation'

interface Props {
    parent?: boolean
    item: NavLink
    navHover?: boolean
    settings: Settings
    navVisible?: boolean
    collapsedNavWidth: number
    navigationBorderWidth: number
    toggleNavVisibility: () => void
    isSubToSub?: NavGroup | undefined
}

// ** Styled Components
const MenuNavLink = styled(ListItemButton)<ListItemButtonProps & { component?: ElementType; href: string; target?: '_blank' | undefined }>(({ theme }) => ({
    width: '100%',
    borderRadius: 8,
    transition: 'padding-left .25s ease-in-out',
    '&.active': {
        '&, &:hover': {
            backgroundColor: theme.palette.primary.light,
            '&.Mui-focusVisible': {
                backgroundColor: theme.palette.primary.main
            }
        },
        '& .MuiTypography-root': {
            fontWeight: 500,
            color: `${theme.palette.common.white} !important`
        },
        '& .MuiListItemIcon-root': {
            color: `${theme.palette.common.white} !important`
        }
    }
}))

const MenuItemTextMetaWrapper = styled(Box)<BoxProps>(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    transition: 'opacity .25s ease-in-out',
    ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
}))

const VerticalNavLink = ({ item, parent, navHover, settings, navVisible, isSubToSub, collapsedNavWidth, toggleNavVisibility, navigationBorderWidth }: Props) => {
    // ** Hooks
    const router = useRouter()

    // ** Vars
    const { navCollapsed } = settings

    const icon = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon

    const isNavLinkActive = () => {
        if (router.pathname === item.path || handleURLQueries(router, item.path)) {
            return true
        } else {
            return false
        }
    }

    const { toString } = useSearchParams()

    const queryParams = useMemo(() => {
        if (toString().length) {
            return '/?' + toString()
        } else {
            return ''
        }
    }, [toString()])

    return (
        <CanViewNavLink navLink={item}>
            <ListItem
                disablePadding
                className='nav-link'
                disabled={item.disabled || false}
                sx={{
                    mt: 1.5,
                    transition: 'padding .25s ease-in-out',
                    px: theme => (parent ? '0 !important' : `${theme.spacing(navCollapsed && !navHover ? 2 : 3)} !important`)
                }}
            >
                <MenuNavLink
                    component={Link}
                    {...(item.disabled && { tabIndex: -1 })}
                    className={isNavLinkActive() ? 'active' : ''}
                    href={item.path === router.pathname ? item.path + queryParams : item.path === undefined ? '/' : `${item.path}`}
                    {...(item.openInNewTab ? { target: '_blank' } : null)}
                    onClick={e => {
                        if (item.path === undefined) {
                            e.preventDefault()
                            e.stopPropagation()
                        }
                        if (navVisible) {
                            toggleNavVisibility()
                        }
                    }}
                    sx={{
                        py: 2.25,
                        ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
                        pr: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8 : 3,
                        pl: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8 : 4
                    }}
                >
                    {isSubToSub ? null : (
                        <ListItemIcon
                            sx={{
                                transition: 'margin .25s ease-in-out',
                                color: parent ? 'text.secondary' : 'text.primary',
                                ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2 }),
                                ...(parent ? { ml: 2, mr: 4 } : {}), // This line should be after (navCollapsed && !navHover) condition for proper styling
                                '& svg': {
                                    ...(!parent ? { fontSize: '1.5rem' } : { fontSize: '0.5rem' }),
                                    ...(parent && item.icon ? { fontSize: '0.875rem' } : {})
                                }
                            }}
                        >
                            <UserIcon icon={icon as string} />
                        </ListItemIcon>
                    )}

                    <MenuItemTextMetaWrapper
                        sx={{
                            ...(isSubToSub ? { ml: 8 } : {}),
                            ...(navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 })
                        }}
                    >
                        <Typography
                            {...((themeConfig.menuTextTruncate || (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
                                noWrap: true
                            })}
                        >
                            <Translations text={item.title} />
                        </Typography>
                        {item.badgeContent ? (
                            <Chip
                                size='small'
                                label={item.badgeContent}
                                color={item.badgeColor || 'primary'}
                                sx={{ '& .MuiChip-label': { px: 2.5, lineHeight: 1.385, textTransform: 'capitalize' } }}
                            />
                        ) : null}
                    </MenuItemTextMetaWrapper>
                </MenuNavLink>
            </ListItem>
        </CanViewNavLink>
    )
}

export default VerticalNavLink
