// ** React Imports
import { ReactNode, ReactElement, useEffect } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Hooks Import./../../store/slices/auth'
import { getCookie } from 'cookies-next'
import { useAuth } from '../../../hooks/useAuth'
import { shallowEqual, useSelector } from 'react-redux'
import { AuthState } from '../../../store/slices/auth'

interface AuthGuardProps {
    children: ReactNode
    fallback: ReactElement | null
}

const AuthGuard = (props: AuthGuardProps) => {
    const { children, fallback } = props
    const router = useRouter()
    const user = useSelector((state: { auth: AuthState }) => state.auth.user, shallowEqual)
    const authLoading = useSelector((state: { auth: AuthState }) => state.auth.loading, shallowEqual)

    useEffect(
        () => {
            if (!router.isReady) {
                return
            }
            const token = getCookie('XSRF-TOKEN', { secure: true, httpOnly: true })

            if (user === null || !token) {
                if (router.asPath !== '/' && router.isReady) {
                    ;(async () => {
                        await router.replace({
                            pathname: '/login',
                            query: { returnUrl: router.asPath }
                        })
                    })()
                } else {
                    ;(async () => {
                        await router.replace('/login')
                    })()
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [router.route]
    )
    const auth = useAuth()
    if (auth.loading || authLoading) {
        return fallback
    }

    return <>{children}</>
}

export default AuthGuard
