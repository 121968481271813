export default {
    setCsrf: 'sanctum/csrf-cookie',
    login: 'admin/auth/login',
    logout: 'api/backoffice/admin/auth/logout',
    register: 'admin/auth/register',
    forgotPassword: 'admin/auth/forgot_password',
    resetPassword: 'admin/auth/update_password',
    resendPasswordResetLink: 'admin/auth/resend_password',
    loginEndpoint: '/jwt/login',
    storageTokenKeyName: 'accessToken',
    onTokenExpiration: 'refreshToken' // logout | refreshToken
}
