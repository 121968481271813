import React from 'react'
import * as Sentry from '@sentry/nextjs'
import Error500 from '../../pages/500'
;('use client')

interface ErrorBoundaryProps {
    children: React.ReactNode
    router: any
}
type ErrorBoundaryState = { hasError: boolean }
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: { children: React.ReactNode; router: any; hasError: boolean }) {
        super(props)

        // Define a state variable to track whether is an error or not
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI

        return { hasError: true }
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can use your own error logging service here
        console.log({ error, errorInfo })
        Sentry.captureException(errorInfo)
    }

    render() {
        const { router } = this.props.router

        // Check if the error is thrown
        if (this.state?.hasError) {
            // You can render any custom fallback UI
            return <Error500 />
        }

        // Return children components in case of no error

        return this.props.children
    }
}

export default ErrorBoundary
