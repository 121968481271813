import React, { useCallback, useContext, useMemo } from 'react'
import { SettingsContext } from '../@core/context/settingsContext'

export default function useSvgLogo() {
    const settingsContextValue = useContext(SettingsContext)
    const color = useCallback(() => {
        if (settingsContextValue.settings.mode === 'semi-dark') {
            return '#1C5E4C'
        } else {
            return 'white'
        }
    }, [settingsContextValue.settings.mode])
    const svgAuthLogo = useMemo(() => {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 0 400.43 400.89' width='80px' height='80px'>
                <g>
                    <g>
                        <path
                            fill={color()}
                            d='m287.11,155c-3.17-1.63-4.34,1.72-5.73,4-1.35,2.21-8.21,8.42-12.47,7.19-.65-.19-1.29-.09-1.87-.24-.35-.04-.71-.03-1.1.19-3.47,1.89-6.86,1.12-10.48,0-5.22-1.61-9.92-5.05-15.09-6.01-10.14-1.88-20.32-4.08-30.74-3.56-7.27.36-9.1-1.49-9.58-9.35-.06-.88.04-1.77-.09-2.63-.54-3.66,1.29-4.54,4.28-4.48,3.52.07,7.12.32,10.54-.34,1.42-.27,3.46-.85,4.7-.68l7.6,5.62,11.27-7.58,2.82-7.84,2.08.13,9.68-6.14-10.91-1.18-12.25,3.14-16.3-14.38.49.39,13.48-.78-4.9-11.63-14.22.13.12.39,6.01,11.76-9.31-2.88,7.72,10.45-2.08,6.67c-3.93-1.95-10.87-5.85-14.43-8.75-2.38-1.93-5.59-2.2-3.34-6.51.29-.56.08-1.5-.07-2.22-.87-4.04-1.12-7.77,3.56-9.44.39-.14.67-.69.98-1.06.81-.96,1.63-2.03.76-3.32-.86-1.27-2.25-1.68-3.45-.83-3.15,2.24-5.99,1.26-8.9-.51-2.52-1.54-4.92-1.09-7.27.62-6,4.38-7.12,10.24-3.14,16.89.8,1.34,2.36,3.03.18,4.01-5.73,2.56-5.76,8.03-6.24,13.16-.56,6.02.4,12.02,1.27,17.95.44,3.04,0,6.89,3.93,8.34.84.31.6,1.78.58,2.79-.28,11.23-2.4,21.79-8.9,31.07-2.48,3.54-4.96,5.9-9.4,6.05-7.7.25-14.9,2.01-21.51,7.07-3.06,2.35-7.31,4.47-12.02,2.17-3.24-1.58-4.47-.43-4.96,3.35-.81,6.21-1.39,12.45-1.26,18.74.04,1.82,0,4.22,2.33,4.42,2.25.19,2.63-2.1,3.1-3.9.47-1.83,1.01-3.18,1.69-4.13.45-3.32,2.69-6.31,6.72-6.33.75-.84,2.24-.97,4.71-.67,1.61.19,2.99-.29,4.39-1.04,1.67-.9,3.34-1.96,5.13-2.44,8.07-2.16,16.17-4.18,24.27-6.16,4.04-.98,7.2-2.9,9.73-6.71,8.44-12.71,18.89-22.33,34.45-23.11.12,0,.23-.06.34-.08,11.34-2.4,22.59-2.99,33.77,1.11,2.74,1.01,5.74,1.28,8.64,1.74,6.61,1.05,13.54.39,19.5,4.76,2.56,1.88,4.65.91,5.8-2.25,2.12-5.83,4.46-11.58,6.38-17.49.83-2.55,2.21-6.02-.97-7.65m-89.34-24.79c.09-1.03.82-1.62,1.69-1.48,4.01.65,8.11.85,11.9,2.58.46.21,1.07.56.86,1.74-4.17.34-8.39.38-12.6-.6-1.13-.26-1.99-.85-1.86-2.25'
                        />
                        <polygon fill={color()} points='248.9 113.34 232.6 102.1 227.46 110.46 242.04 122.09 248.9 113.34' />
                        <path
                            fill={color()}
                            d='m289.21,189.13c0,1.75-1.29,3.12-2.96,3.12s-2.98-1.37-2.98-3.12,1.32-3.08,2.98-3.08,2.96,1.37,2.96,3.08m-5.2,0c0,1.37.95,2.46,2.26,2.46s2.2-1.09,2.2-2.44-.93-2.48-2.22-2.48-2.24,1.11-2.24,2.46m1.78,1.62h-.67v-3.08c.26-.06.63-.09,1.11-.09.55,0,.79.09,1.01.23.16.13.28.38.28.68,0,.34-.25.6-.6.71v.04c.28.11.44.34.53.75.09.47.14.66.21.77h-.72c-.09-.11-.14-.39-.23-.75-.05-.34-.23-.49-.6-.49h-.32v1.24h0Zm.02-1.75h.32c.37,0,.67-.13.67-.45,0-.28-.19-.47-.62-.47-.18,0-.3.02-.37.04v.88h0Z'
                        />
                        <path
                            fill={color()}
                            d='m95.5,271.82c0,5.92-2.07,9.8-6.05,11.66l5.72,23.14c.25,1.06-.25,1.59-1.16,1.59h-8.62c-.83,0-1.24-.44-1.41-1.24l-5.55-22.43-5.72-1.8v24.06c0,.88-.5,1.41-1.33,1.41h-8.61c-.83,0-1.33-.53-1.33-1.41v-67.83c0-.88.5-1.41,1.33-1.41h20.79c7.79,0,11.93,4.42,11.93,12.72v21.55h.01Zm-14.58,1.06c1.91.71,3.31-1.05,3.31-3.34v-16.79c0-2.3-1.16-3.53-3.31-3.53h-8.2v20.81l8.2,2.86h0Z'
                        />
                        <path
                            fill={color()}
                            d='m303.77,271.82c0,5.92-2.07,9.8-6.05,11.66l5.72,23.14c.25,1.06-.25,1.59-1.16,1.59h-8.62c-.83,0-1.24-.44-1.41-1.24l-5.55-22.43-5.72-1.8v24.06c0,.88-.5,1.41-1.33,1.41h-8.61c-.83,0-1.33-.53-1.33-1.41v-67.83c0-.88.5-1.41,1.33-1.41h20.79c7.79,0,11.93,4.42,11.93,12.72v21.55h0Zm-14.58,1.06c1.91.71,3.31-1.05,3.31-3.34v-16.79c0-2.3-1.16-3.53-3.31-3.53h-8.2v20.81l8.2,2.86h0Z'
                        />
                        <path
                            fill={color()}
                            d='m135.25,237.55c.91,0,1.66.79,1.66,1.77v56.17c0,8.3-4.14,12.72-11.93,12.72h-9.28c-7.79,0-11.93-4.42-11.93-12.72v-56.17c0-.97.74-1.77,1.66-1.77h7.95c.91,0,1.66.79,1.66,1.77v53.7c0,2.38,1.16,3.53,3.31,3.53h4.31c2.15,0,3.31-1.15,3.31-3.53v-53.7c0-.97.75-1.77,1.66-1.77h7.62Z'
                        />
                        <path
                            fill={color()}
                            d='m166.39,237.55c7.79,0,11.93,4.42,11.93,12.72v10.38c0,.88-.41,1.41-1.32,1.41l-8.62-3.11c-.86-.31-1.32-.64-1.32-1.53v-4.67c0-2.3-1.08-3.53-3.31-3.53h-3.98c-2.07,0-3.31,1.24-3.31,3.53v12.19l19.71,6.89c1.41.62,2.15,1.59,2.15,3.27v20.4c0,8.3-4.14,12.72-11.93,12.72h-9.28c-7.7,0-11.93-4.42-11.93-12.72v-10.86c0-.97.5-1.41,1.32-1.41l8.62,3.14c.93.32,1.32.56,1.32,1.53v5.12c0,2.38,1.24,3.53,3.31,3.53h3.98c2.15,0,3.31-1.15,3.31-3.53v-11.75l-19.63-6.89c-1.49-.53-2.24-1.59-2.24-3.27v-20.85c0-8.3,4.22-12.72,11.93-12.72h9.29Z'
                        />
                        <path
                            fill={color()}
                            d='m332.87,237.55c7.79,0,11.93,4.42,11.93,12.72v10.38c0,.88-.41,1.41-1.32,1.41l-8.62-3.11c-.86-.31-1.32-.64-1.32-1.53v-4.67c0-2.3-1.08-3.53-3.31-3.53h-3.98c-2.07,0-3.31,1.24-3.31,3.53v12.19l19.71,6.89c1.41.62,2.15,1.59,2.15,3.27v20.4c0,8.3-4.14,12.72-11.93,12.72h-9.28c-7.7,0-11.93-4.42-11.93-12.72v-10.86c0-.97.5-1.41,1.32-1.41l8.62,3.14c.93.32,1.32.56,1.32,1.53v5.12c0,2.38,1.24,3.53,3.31,3.53h3.98c2.15,0,3.31-1.15,3.31-3.53v-11.75l-19.63-6.89c-1.49-.53-2.24-1.59-2.24-3.27v-20.85c0-8.3,4.22-12.72,11.93-12.72h9.29Z'
                        />
                        <path
                            fill={color()}
                            d='m208.47,238.97c0-.88.5-1.41,1.32-1.41h8.53c.99,0,1.41.53,1.41,1.41v67.83c0,.88-.41,1.41-1.41,1.41h-8.53c-.83,0-1.32-.53-1.32-1.41v-28.09h-10.6v28.09c0,.88-.5,1.41-1.32,1.41h-8.53c-.99,0-1.41-.53-1.41-1.41v-67.83c0-.88.41-1.41,1.41-1.41h8.53c.83,0,1.32.53,1.32,1.41v28.09h10.6v-28.09Z'
                        />
                        <path
                            fill={color()}
                            d='m261.06,247.8c0,.88-.33,1.5-1.32,1.5h-20.71v17.66h15.82c.83,0,1.33.53,1.33,1.5v8.74c0,.97-.5,1.5-1.33,1.5h-15.82v17.84h20.71c.99,0,1.32.44,1.32,1.41v8.83c0,.88-.33,1.41-1.32,1.41h-30.48c-.74,0-1.24-.53-1.24-1.41v-67.83c0-.88.5-1.41,1.24-1.41h30.48c.99,0,1.32.53,1.32,1.41v8.85Z'
                        />
                    </g>
                </g>
            </svg>
        )
    }, [color])
    const svgDashboardLogo = useMemo(() => {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 150 150' width='40px' height='40px'>
                <g>
                    <rect fill='none' width='40' height='40' />
                    <g>
                        <g>
                            <path
                                fill={color()}
                                d='m129.45,75.45c-1.98-.95-2.71,1.01-3.58,2.35-.84,1.29-5.12,4.93-7.79,4.21-.41-.11-.81-.05-1.17-.14-.22-.02-.44-.01-.69.11-2.17,1.11-4.29.65-6.54,0-3.26-.94-6.2-2.96-9.42-3.52-6.33-1.1-12.69-2.39-19.2-2.09-4.54.21-5.68-.87-5.98-5.48-.03-.51.02-1.04-.05-1.54-.33-2.15.8-2.66,2.68-2.62,2.2.04,4.45.19,6.58-.2.88-.16,2.16-.5,2.94-.4l4.74,3.29,7.04-4.44,1.76-4.59,1.3.08,6.05-3.6-6.81-.69-7.65,1.84-10.18-8.42.31.23,8.42-.46-3.06-6.81-8.88.08.08.23,3.75,6.89-5.82-1.68,4.82,6.12-1.3,3.9c-2.45-1.14-6.79-3.42-9.01-5.12-1.48-1.13-3.49-1.29-2.08-3.81.18-.33.05-.88-.05-1.3-.54-2.37-.7-4.55,2.22-5.53.25-.08.42-.4.61-.62.5-.57,1.02-1.19.47-1.95-.53-.74-1.41-.98-2.15-.48-1.97,1.31-3.74.74-5.56-.3-1.58-.9-3.07-.64-4.54.36-3.75,2.57-4.45,6-1.96,9.89.5.78,1.48,1.78.11,2.35-3.58,1.5-3.6,4.7-3.89,7.71-.35,3.53.25,7.04.79,10.51.28,1.78,0,4.03,2.46,4.89.52.18.38,1.04.36,1.63-.17,6.58-1.5,12.76-5.56,18.19-1.55,2.07-3.1,3.45-5.87,3.54-4.81.15-9.31,1.18-13.43,4.14-1.91,1.37-4.57,2.62-7.51,1.27-2.02-.93-2.79-.25-3.1,1.96-.5,3.64-.87,7.29-.79,10.97.02,1.07,0,2.47,1.46,2.59,1.41.11,1.64-1.23,1.93-2.28.3-1.07.63-1.86,1.05-2.42.28-1.94,1.68-3.69,4.2-3.71.47-.49,1.4-.57,2.94-.39,1,.11,1.87-.17,2.74-.61,1.04-.53,2.08-1.15,3.2-1.43,5.04-1.26,10.1-2.45,15.16-3.61,2.52-.58,4.5-1.7,6.07-3.93,5.27-7.45,11.79-13.08,21.51-13.54.07,0,.14-.03.21-.05,7.08-1.41,14.11-1.75,21.09.65,1.71.59,3.58.75,5.39,1.02,4.13.62,8.46.23,12.17,2.79,1.6,1.1,2.9.53,3.62-1.32,1.33-3.42,2.78-6.78,3.98-10.24.52-1.49,1.38-3.52-.6-4.48Zm-55.79-14.52c.06-.6.51-.95,1.06-.87,2.5.38,5.06.49,7.43,1.51.28.12.67.33.54,1.02-2.61.2-5.24.22-7.87-.35-.7-.15-1.24-.5-1.16-1.32Z'
                            />
                            <polygon fill={color()} points='105.59 51.05 95.41 44.47 92.19 49.37 101.3 56.18 105.59 51.05' />
                        </g>
                        <path
                            fill={color()}
                            d='m130.76,95.44c0,1.02-.8,1.83-1.85,1.83s-1.86-.8-1.86-1.83.83-1.81,1.86-1.81,1.85.8,1.85,1.81Zm-3.25,0c0,.8.6,1.44,1.41,1.44s1.38-.64,1.38-1.43-.58-1.45-1.39-1.45-1.4.65-1.4,1.44Zm1.11.95h-.42v-1.81c.17-.03.4-.06.69-.06.34,0,.5.06.63.13.1.08.18.22.18.4,0,.2-.15.35-.37.42v.02c.18.07.28.2.33.44.06.28.09.39.13.45h-.45c-.05-.07-.09-.23-.14-.44-.03-.2-.14-.29-.37-.29h-.2v.73Zm.01-1.02h.2c.23,0,.42-.08.42-.26,0-.17-.12-.28-.39-.28-.11,0-.19.01-.23.02v.52Z'
                        />
                    </g>
                </g>
            </svg>
        )
    }, [color])
    const svgWhiteDashboardLogo = useMemo(() => {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 150 150' width='40px' height='40px'>
                <g>
                    <rect fill='none' width='40' height='40' />
                    <g>
                        <g>
                            <path
                                fill={'#fff'}
                                d='m129.45,75.45c-1.98-.95-2.71,1.01-3.58,2.35-.84,1.29-5.12,4.93-7.79,4.21-.41-.11-.81-.05-1.17-.14-.22-.02-.44-.01-.69.11-2.17,1.11-4.29.65-6.54,0-3.26-.94-6.2-2.96-9.42-3.52-6.33-1.1-12.69-2.39-19.2-2.09-4.54.21-5.68-.87-5.98-5.48-.03-.51.02-1.04-.05-1.54-.33-2.15.8-2.66,2.68-2.62,2.2.04,4.45.19,6.58-.2.88-.16,2.16-.5,2.94-.4l4.74,3.29,7.04-4.44,1.76-4.59,1.3.08,6.05-3.6-6.81-.69-7.65,1.84-10.18-8.42.31.23,8.42-.46-3.06-6.81-8.88.08.08.23,3.75,6.89-5.82-1.68,4.82,6.12-1.3,3.9c-2.45-1.14-6.79-3.42-9.01-5.12-1.48-1.13-3.49-1.29-2.08-3.81.18-.33.05-.88-.05-1.3-.54-2.37-.7-4.55,2.22-5.53.25-.08.42-.4.61-.62.5-.57,1.02-1.19.47-1.95-.53-.74-1.41-.98-2.15-.48-1.97,1.31-3.74.74-5.56-.3-1.58-.9-3.07-.64-4.54.36-3.75,2.57-4.45,6-1.96,9.89.5.78,1.48,1.78.11,2.35-3.58,1.5-3.6,4.7-3.89,7.71-.35,3.53.25,7.04.79,10.51.28,1.78,0,4.03,2.46,4.89.52.18.38,1.04.36,1.63-.17,6.58-1.5,12.76-5.56,18.19-1.55,2.07-3.1,3.45-5.87,3.54-4.81.15-9.31,1.18-13.43,4.14-1.91,1.37-4.57,2.62-7.51,1.27-2.02-.93-2.79-.25-3.1,1.96-.5,3.64-.87,7.29-.79,10.97.02,1.07,0,2.47,1.46,2.59,1.41.11,1.64-1.23,1.93-2.28.3-1.07.63-1.86,1.05-2.42.28-1.94,1.68-3.69,4.2-3.71.47-.49,1.4-.57,2.94-.39,1,.11,1.87-.17,2.74-.61,1.04-.53,2.08-1.15,3.2-1.43,5.04-1.26,10.1-2.45,15.16-3.61,2.52-.58,4.5-1.7,6.07-3.93,5.27-7.45,11.79-13.08,21.51-13.54.07,0,.14-.03.21-.05,7.08-1.41,14.11-1.75,21.09.65,1.71.59,3.58.75,5.39,1.02,4.13.62,8.46.23,12.17,2.79,1.6,1.1,2.9.53,3.62-1.32,1.33-3.42,2.78-6.78,3.98-10.24.52-1.49,1.38-3.52-.6-4.48Zm-55.79-14.52c.06-.6.51-.95,1.06-.87,2.5.38,5.06.49,7.43,1.51.28.12.67.33.54,1.02-2.61.2-5.24.22-7.87-.35-.7-.15-1.24-.5-1.16-1.32Z'
                            />
                            <polygon fill={'#fff'} points='105.59 51.05 95.41 44.47 92.19 49.37 101.3 56.18 105.59 51.05' />
                        </g>
                        <path
                            fill={'#fff'}
                            d='m130.76,95.44c0,1.02-.8,1.83-1.85,1.83s-1.86-.8-1.86-1.83.83-1.81,1.86-1.81,1.85.8,1.85,1.81Zm-3.25,0c0,.8.6,1.44,1.41,1.44s1.38-.64,1.38-1.43-.58-1.45-1.39-1.45-1.4.65-1.4,1.44Zm1.11.95h-.42v-1.81c.17-.03.4-.06.69-.06.34,0,.5.06.63.13.1.08.18.22.18.4,0,.2-.15.35-.37.42v.02c.18.07.28.2.33.44.06.28.09.39.13.45h-.45c-.05-.07-.09-.23-.14-.44-.03-.2-.14-.29-.37-.29h-.2v.73Zm.01-1.02h.2c.23,0,.42-.08.42-.26,0-.17-.12-.28-.39-.28-.11,0-.19.01-.23.02v.52Z'
                        />
                    </g>
                </g>
            </svg>
        )
    }, [])
    const svgIconLogo = useMemo(() => {
        return (
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 150 150' width='80px' height='80px'>
                <g>
                    <rect fill='none' width='150' height='150' />
                    <g>
                        <g>
                            <path
                                fill={color()}
                                d='m129.45,75.45c-1.98-.95-2.71,1.01-3.58,2.35-.84,1.29-5.12,4.93-7.79,4.21-.41-.11-.81-.05-1.17-.14-.22-.02-.44-.01-.69.11-2.17,1.11-4.29.65-6.54,0-3.26-.94-6.2-2.96-9.42-3.52-6.33-1.1-12.69-2.39-19.2-2.09-4.54.21-5.68-.87-5.98-5.48-.03-.51.02-1.04-.05-1.54-.33-2.15.8-2.66,2.68-2.62,2.2.04,4.45.19,6.58-.2.88-.16,2.16-.5,2.94-.4l4.74,3.29,7.04-4.44,1.76-4.59,1.3.08,6.05-3.6-6.81-.69-7.65,1.84-10.18-8.42.31.23,8.42-.46-3.06-6.81-8.88.08.08.23,3.75,6.89-5.82-1.68,4.82,6.12-1.3,3.9c-2.45-1.14-6.79-3.42-9.01-5.12-1.48-1.13-3.49-1.29-2.08-3.81.18-.33.05-.88-.05-1.3-.54-2.37-.7-4.55,2.22-5.53.25-.08.42-.4.61-.62.5-.57,1.02-1.19.47-1.95-.53-.74-1.41-.98-2.15-.48-1.97,1.31-3.74.74-5.56-.3-1.58-.9-3.07-.64-4.54.36-3.75,2.57-4.45,6-1.96,9.89.5.78,1.48,1.78.11,2.35-3.58,1.5-3.6,4.7-3.89,7.71-.35,3.53.25,7.04.79,10.51.28,1.78,0,4.03,2.46,4.89.52.18.38,1.04.36,1.63-.17,6.58-1.5,12.76-5.56,18.19-1.55,2.07-3.1,3.45-5.87,3.54-4.81.15-9.31,1.18-13.43,4.14-1.91,1.37-4.57,2.62-7.51,1.27-2.02-.93-2.79-.25-3.1,1.96-.5,3.64-.87,7.29-.79,10.97.02,1.07,0,2.47,1.46,2.59,1.41.11,1.64-1.23,1.93-2.28.3-1.07.63-1.86,1.05-2.42.28-1.94,1.68-3.69,4.2-3.71.47-.49,1.4-.57,2.94-.39,1,.11,1.87-.17,2.74-.61,1.04-.53,2.08-1.15,3.2-1.43,5.04-1.26,10.1-2.45,15.16-3.61,2.52-.58,4.5-1.7,6.07-3.93,5.27-7.45,11.79-13.08,21.51-13.54.07,0,.14-.03.21-.05,7.08-1.41,14.11-1.75,21.09.65,1.71.59,3.58.75,5.39,1.02,4.13.62,8.46.23,12.17,2.79,1.6,1.1,2.9.53,3.62-1.32,1.33-3.42,2.78-6.78,3.98-10.24.52-1.49,1.38-3.52-.6-4.48Zm-55.79-14.52c.06-.6.51-.95,1.06-.87,2.5.38,5.06.49,7.43,1.51.28.12.67.33.54,1.02-2.61.2-5.24.22-7.87-.35-.7-.15-1.24-.5-1.16-1.32Z'
                            />
                            <polygon fill={color()} points='105.59 51.05 95.41 44.47 92.19 49.37 101.3 56.18 105.59 51.05' />
                        </g>
                        <path
                            fill={color()}
                            d='m130.76,95.44c0,1.02-.8,1.83-1.85,1.83s-1.86-.8-1.86-1.83.83-1.81,1.86-1.81,1.85.8,1.85,1.81Zm-3.25,0c0,.8.6,1.44,1.41,1.44s1.38-.64,1.38-1.43-.58-1.45-1.39-1.45-1.4.65-1.4,1.44Zm1.11.95h-.42v-1.81c.17-.03.4-.06.69-.06.34,0,.5.06.63.13.1.08.18.22.18.4,0,.2-.15.35-.37.42v.02c.18.07.28.2.33.44.06.28.09.39.13.45h-.45c-.05-.07-.09-.23-.14-.44-.03-.2-.14-.29-.37-.29h-.2v.73Zm.01-1.02h.2c.23,0,.42-.08.42-.26,0-.17-.12-.28-.39-.28-.11,0-.19.01-.23.02v.52Z'
                        />
                    </g>
                </g>
            </svg>
        )
    }, [color])
    const svgTextLogo = useMemo(() => {
        return (
            <svg data-name='Livello 2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 361.58 110.97' width='130.5' height='55.5'>
                <g data-name='Livello 1'>
                    <path
                        fill={color()}
                        d='m227.23,4.14c0,2.35-1.84,4.2-4.25,4.2s-4.27-1.84-4.27-4.2,1.9-4.14,4.27-4.14,4.25,1.84,4.25,4.14Zm-7.46,0c0,1.84,1.37,3.31,3.24,3.31s3.16-1.47,3.16-3.29-1.34-3.34-3.18-3.34-3.21,1.49-3.21,3.31Zm2.55,2.17h-.96V2.17c.38-.08.91-.13,1.59-.13.78,0,1.14.13,1.44.3.23.18.4.51.4.91,0,.45-.35.81-.86.96v.05c.4.15.63.45.76,1.01.13.63.2.88.3,1.04h-1.04c-.13-.15-.2-.53-.33-1.01-.07-.45-.33-.66-.86-.66h-.45v1.67Zm.02-2.35h.45c.53,0,.96-.18.96-.61,0-.38-.28-.63-.88-.63-.25,0-.43.03-.53.05v1.19Z'
                    />
                    <g>
                        <path
                            fill={color()}
                            d='m25.96,24.72c0,4.23-1.58,7.01-4.61,8.34l4.36,16.55c.19.76-.19,1.14-.88,1.14h-6.57c-.63,0-.95-.32-1.07-.88l-4.23-16.04-4.36-1.29v17.21c0,.63-.38,1.01-1.01,1.01H1.01c-.63,0-1.01-.38-1.01-1.01V1.23C0,.59.38.21,1.01.21h15.85c5.94,0,9.1,3.16,9.1,9.1v15.41Zm-11.12.76c1.46.51,2.53-.75,2.53-2.39v-12.01c0-1.64-.88-2.53-2.53-2.53h-6.25v14.88l6.25,2.05Z'
                        />
                        <path
                            fill={color()}
                            d='m184.79,24.72c0,4.23-1.58,7.01-4.61,8.34l4.36,16.55c.19.76-.19,1.14-.88,1.14h-6.57c-.63,0-.95-.32-1.07-.88l-4.23-16.04-4.36-1.29v17.21c0,.63-.38,1.01-1.01,1.01h-6.57c-.63,0-1.01-.38-1.01-1.01V1.23c0-.63.38-1.01,1.01-1.01h15.85c5.94,0,9.1,3.16,9.1,9.1v15.41Zm-11.12.76c1.46.51,2.53-.75,2.53-2.39v-12.01c0-1.64-.88-2.53-2.53-2.53h-6.25v14.88l6.25,2.05Z'
                        />
                        <path
                            fill={color()}
                            d='m56.28.21c.7,0,1.26.57,1.26,1.26v40.17c0,5.94-3.16,9.1-9.1,9.1h-7.07c-5.94,0-9.1-3.16-9.1-9.1V1.48c0-.7.57-1.26,1.26-1.26h6.06c.7,0,1.26.57,1.26,1.26v38.4c0,1.71.88,2.53,2.53,2.53h3.28c1.64,0,2.53-.82,2.53-2.53V1.48c0-.7.57-1.26,1.26-1.26h5.81Z'
                        />
                        <path
                            fill={color()}
                            d='m80.02.21c5.94,0,9.1,3.16,9.1,9.1v7.42c0,.63-.32,1.01-1.01,1.01l-6.57-2.23c-.65-.22-1.01-.46-1.01-1.09v-3.34c0-1.64-.82-2.53-2.53-2.53h-3.03c-1.58,0-2.53.88-2.53,2.53v8.72l15.03,4.93c1.07.44,1.64,1.14,1.64,2.34v14.59c0,5.94-3.16,9.1-9.1,9.1h-7.07c-5.87,0-9.1-3.16-9.1-9.1v-7.76c0-.7.38-1.01,1.01-1.01l6.57,2.25c.71.23,1.01.4,1.01,1.1v3.66c0,1.71.95,2.53,2.53,2.53h3.03c1.64,0,2.53-.82,2.53-2.53v-8.4l-14.97-4.93c-1.14-.38-1.71-1.14-1.71-2.34v-14.91c0-5.94,3.22-9.1,9.1-9.1h7.07Z'
                        />
                        <path
                            fill={color()}
                            d='m206.98.21c5.94,0,9.1,3.16,9.1,9.1v7.42c0,.63-.32,1.01-1.01,1.01l-6.57-2.23c-.65-.22-1.01-.46-1.01-1.09v-3.34c0-1.64-.82-2.53-2.53-2.53h-3.03c-1.58,0-2.53.88-2.53,2.53v8.72l15.03,4.93c1.07.44,1.64,1.14,1.64,2.34v14.59c0,5.94-3.16,9.1-9.1,9.1h-7.07c-5.87,0-9.1-3.16-9.1-9.1v-7.76c0-.7.38-1.01,1.01-1.01l6.57,2.25c.71.23,1.01.4,1.01,1.1v3.66c0,1.71.95,2.53,2.53,2.53h3.03c1.64,0,2.53-.82,2.53-2.53v-8.4l-14.97-4.93c-1.14-.38-1.71-1.14-1.71-2.34v-14.91c0-5.94,3.22-9.1,9.1-9.1h7.07Z'
                        />
                        <path
                            fill={color()}
                            d='m112.11,1.23c0-.63.38-1.01,1.01-1.01h6.51c.76,0,1.07.38,1.07,1.01v48.51c0,.63-.32,1.01-1.07,1.01h-6.51c-.63,0-1.01-.38-1.01-1.01v-20.09h-8.09v20.09c0,.63-.38,1.01-1.01,1.01h-6.51c-.76,0-1.07-.38-1.07-1.01V1.23c0-.63.32-1.01,1.07-1.01h6.51c.63,0,1.01.38,1.01,1.01v20.09h8.09V1.23Z'
                        />
                        <path
                            fill={color()}
                            d='m152.22,7.54c0,.63-.25,1.07-1.01,1.07h-15.79v12.63h12.06c.63,0,1.01.38,1.01,1.07v6.25c0,.7-.38,1.07-1.01,1.07h-12.06v12.76h15.79c.76,0,1.01.32,1.01,1.01v6.32c0,.63-.25,1.01-1.01,1.01h-23.24c-.57,0-.95-.38-.95-1.01V1.23c0-.63.38-1.01.95-1.01h23.24c.76,0,1.01.38,1.01,1.01v6.32Z'
                        />
                    </g>
                </g>
            </svg>
        )
    }, [color])
    const svgWhiteTextLogo = useMemo(() => {
        return (
            <svg data-name='Livello 2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 361.58 110.97' width='130.5' height='55.5'>
                <g data-name='Livello 1'>
                    <path
                        fill={'#fff'}
                        d='m227.23,4.14c0,2.35-1.84,4.2-4.25,4.2s-4.27-1.84-4.27-4.2,1.9-4.14,4.27-4.14,4.25,1.84,4.25,4.14Zm-7.46,0c0,1.84,1.37,3.31,3.24,3.31s3.16-1.47,3.16-3.29-1.34-3.34-3.18-3.34-3.21,1.49-3.21,3.31Zm2.55,2.17h-.96V2.17c.38-.08.91-.13,1.59-.13.78,0,1.14.13,1.44.3.23.18.4.51.4.91,0,.45-.35.81-.86.96v.05c.4.15.63.45.76,1.01.13.63.2.88.3,1.04h-1.04c-.13-.15-.2-.53-.33-1.01-.07-.45-.33-.66-.86-.66h-.45v1.67Zm.02-2.35h.45c.53,0,.96-.18.96-.61,0-.38-.28-.63-.88-.63-.25,0-.43.03-.53.05v1.19Z'
                    />
                    <g>
                        <path
                            fill={'#fff'}
                            d='m25.96,24.72c0,4.23-1.58,7.01-4.61,8.34l4.36,16.55c.19.76-.19,1.14-.88,1.14h-6.57c-.63,0-.95-.32-1.07-.88l-4.23-16.04-4.36-1.29v17.21c0,.63-.38,1.01-1.01,1.01H1.01c-.63,0-1.01-.38-1.01-1.01V1.23C0,.59.38.21,1.01.21h15.85c5.94,0,9.1,3.16,9.1,9.1v15.41Zm-11.12.76c1.46.51,2.53-.75,2.53-2.39v-12.01c0-1.64-.88-2.53-2.53-2.53h-6.25v14.88l6.25,2.05Z'
                        />
                        <path
                            fill={'#fff'}
                            d='m184.79,24.72c0,4.23-1.58,7.01-4.61,8.34l4.36,16.55c.19.76-.19,1.14-.88,1.14h-6.57c-.63,0-.95-.32-1.07-.88l-4.23-16.04-4.36-1.29v17.21c0,.63-.38,1.01-1.01,1.01h-6.57c-.63,0-1.01-.38-1.01-1.01V1.23c0-.63.38-1.01,1.01-1.01h15.85c5.94,0,9.1,3.16,9.1,9.1v15.41Zm-11.12.76c1.46.51,2.53-.75,2.53-2.39v-12.01c0-1.64-.88-2.53-2.53-2.53h-6.25v14.88l6.25,2.05Z'
                        />
                        <path
                            fill={'#fff'}
                            d='m56.28.21c.7,0,1.26.57,1.26,1.26v40.17c0,5.94-3.16,9.1-9.1,9.1h-7.07c-5.94,0-9.1-3.16-9.1-9.1V1.48c0-.7.57-1.26,1.26-1.26h6.06c.7,0,1.26.57,1.26,1.26v38.4c0,1.71.88,2.53,2.53,2.53h3.28c1.64,0,2.53-.82,2.53-2.53V1.48c0-.7.57-1.26,1.26-1.26h5.81Z'
                        />
                        <path
                            fill={'#fff'}
                            d='m80.02.21c5.94,0,9.1,3.16,9.1,9.1v7.42c0,.63-.32,1.01-1.01,1.01l-6.57-2.23c-.65-.22-1.01-.46-1.01-1.09v-3.34c0-1.64-.82-2.53-2.53-2.53h-3.03c-1.58,0-2.53.88-2.53,2.53v8.72l15.03,4.93c1.07.44,1.64,1.14,1.64,2.34v14.59c0,5.94-3.16,9.1-9.1,9.1h-7.07c-5.87,0-9.1-3.16-9.1-9.1v-7.76c0-.7.38-1.01,1.01-1.01l6.57,2.25c.71.23,1.01.4,1.01,1.1v3.66c0,1.71.95,2.53,2.53,2.53h3.03c1.64,0,2.53-.82,2.53-2.53v-8.4l-14.97-4.93c-1.14-.38-1.71-1.14-1.71-2.34v-14.91c0-5.94,3.22-9.1,9.1-9.1h7.07Z'
                        />
                        <path
                            fill={'#fff'}
                            d='m206.98.21c5.94,0,9.1,3.16,9.1,9.1v7.42c0,.63-.32,1.01-1.01,1.01l-6.57-2.23c-.65-.22-1.01-.46-1.01-1.09v-3.34c0-1.64-.82-2.53-2.53-2.53h-3.03c-1.58,0-2.53.88-2.53,2.53v8.72l15.03,4.93c1.07.44,1.64,1.14,1.64,2.34v14.59c0,5.94-3.16,9.1-9.1,9.1h-7.07c-5.87,0-9.1-3.16-9.1-9.1v-7.76c0-.7.38-1.01,1.01-1.01l6.57,2.25c.71.23,1.01.4,1.01,1.1v3.66c0,1.71.95,2.53,2.53,2.53h3.03c1.64,0,2.53-.82,2.53-2.53v-8.4l-14.97-4.93c-1.14-.38-1.71-1.14-1.71-2.34v-14.91c0-5.94,3.22-9.1,9.1-9.1h7.07Z'
                        />
                        <path
                            fill={'#fff'}
                            d='m112.11,1.23c0-.63.38-1.01,1.01-1.01h6.51c.76,0,1.07.38,1.07,1.01v48.51c0,.63-.32,1.01-1.07,1.01h-6.51c-.63,0-1.01-.38-1.01-1.01v-20.09h-8.09v20.09c0,.63-.38,1.01-1.01,1.01h-6.51c-.76,0-1.07-.38-1.07-1.01V1.23c0-.63.32-1.01,1.07-1.01h6.51c.63,0,1.01.38,1.01,1.01v20.09h8.09V1.23Z'
                        />
                        <path
                            fill={'#fff'}
                            d='m152.22,7.54c0,.63-.25,1.07-1.01,1.07h-15.79v12.63h12.06c.63,0,1.01.38,1.01,1.07v6.25c0,.7-.38,1.07-1.01,1.07h-12.06v12.76h15.79c.76,0,1.01.32,1.01,1.01v6.32c0,.63-.25,1.01-1.01,1.01h-23.24c-.57,0-.95-.38-.95-1.01V1.23c0-.63.38-1.01.95-1.01h23.24c.76,0,1.01.38,1.01,1.01v6.32Z'
                        />
                    </g>
                </g>
            </svg>
        )
    }, [color])

    return { svgAuthLogo, svgIconLogo, svgDashboardLogo, svgTextLogo, svgWhiteDashboardLogo, svgWhiteTextLogo }
}
