// ** React Imports
import { ReactNode, useEffect } from 'react'

// ** Next Imports
import Head from 'next/head'
import { NextRouter, Router, useRouter } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { logEvent, setCurrentScreen } from 'firebase/analytics'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** Config Imports
import 'src/configs/i18n'
import { ACLObj, defaultACLObj } from 'src/configs/acl'
import themeConfig from 'src/configs/themeConfig'
import { PersistGate } from 'redux-persist/integration/react'

// ** Third Party Import
import { Toaster } from 'react-hot-toast'

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout'
import AclGuard from 'src/@core/components/auth/AclGuard'
import ThemeComponent from 'src/@core/theme/ThemeComponent'
import AuthGuard from 'src/@core/components/auth/AuthGuard'
import GuestGuard from 'src/@core/components/auth/GuestGuard'

// ** Spinner Import
import Spinner from 'src/@core/components/spinner'

// ** Contexts
import { AuthProvider } from 'src/context/AuthContext'
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Styled Components
import ReactHotToast from 'src/@core/styles/libs/react-hot-toast'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

import 'src/iconify-bundle/icons-bundle-react'

// ** Global css styles
import '../../styles/globals.css'

// ** Import it scheme for yup
import { it } from 'yup-locales'

// ** Import setLocale method to localize
import { setLocale } from 'yup'
import useFirebase from '../hooks/useFirebase'
import store, { persistor } from '../store'
import { Provider } from 'react-redux'
import * as moment from 'moment'
import 'moment/locale/it'
import ErrorBoundary from '../views/errorBoundary/errorBoundary'

// ** Localize yup for errors in forms
setLocale(it)
moment.locale('it')

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
    Component: NextPage
    emotionCache: EmotionCache
}

type GuardProps = {
    authGuard: boolean
    guestGuard: boolean
    children: ReactNode
}

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
    Router.events.on('routeChangeStart', () => {
        NProgress.start()
    })
    Router.events.on('routeChangeError', () => {
        NProgress.done()
    })
    Router.events.on('routeChangeComplete', () => {
        NProgress.done()
    })
}

const Guard = ({ children, authGuard, guestGuard }: GuardProps) => {
    if (guestGuard) {
        return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>
    } else if (!guestGuard && !authGuard) {
        return <>{children}</>
    } else {
        return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>
    }
}

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
    const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

    // Variables
    const contentHeightFixed = Component.contentHeightFixed ?? false
    const getLayout = Component.getLayout ?? (page => <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>)

    const setConfig = Component.setConfig ?? undefined

    const authGuard: boolean = Component.authGuard ?? true

    const guestGuard: boolean = Component.guestGuard ?? false

    const aclAbilities: ACLObj = Component.acl ?? defaultACLObj
    const { analytics } = useFirebase()
    const routers: NextRouter = useRouter()
    useEffect(() => {
        if (analytics.current) {
            const handleLogs = (url: string) => {
                //analytics.app.setCurrentScreen(url)
                setCurrentScreen(analytics.current, url)
                logEvent(analytics.current, 'screen_view')
            }

            routers.events.on('routeChangeComplete', handleLogs)

            //For First Page
            handleLogs(window.location.pathname)

            //Remvove Event Listener after un-mount
            return () => {
                routers.events.off('routeChangeComplete', handleLogs)
            }
        }
    }, [analytics])
    const metaTitle = 'Rushers Backoffice'
    const metaDescription = 'Pannello di amministrazione Rushers'

    return (
        <CacheProvider value={emotionCache}>
            <Head>
                <title>{`Rushers`}</title>
                <meta name='description' content={`Rushers Backoffice`} />
                <meta name='keywords' content={metaTitle} />
                <meta name='viewport' content='initial-scale=1, width=device-width' />
                <meta name='application-name' content={metaTitle} />
                <meta name='apple-mobile-web-app-capable' content='yes' />
                <meta name='apple-mobile-web-app-status-bar-style' content='default' />
                <meta name='apple-mobile-web-app-title' content={metaTitle} />
                <meta name='description' content={metaDescription} />
                <meta name='format-detection' content='telephone=no' />
                <meta name='mobile-web-app-capable' content='yes' />
                <meta name='msapplication-config' content='/icons/browserconfig.xml' />
                <meta name='msapplication-TileColor' content='#2B5797' />
                <meta name='msapplication-tap-highlight' content='no' />
                <meta name='theme-color' content='#000000' />
                <link rel='apple-touch-icon' href='/icons/touch-icon-iphone.png' />
                <link rel='apple-touch-icon' sizes='152x152' href='/icons/touch-icon-ipad.png' />
                <link rel='apple-touch-icon' sizes='180x180' href='/icons/touch-icon-iphone-retina.png' />
                <link rel='apple-touch-icon' sizes='167x167' href='/icons/touch-icon-ipad-retina.png' />
                <link rel='icon' type='image/png' sizes='32x32' href='/icons/favicon-32x32.png' />
                <link rel='icon' type='image/png' sizes='16x16' href='/icons/favicon-16x16.png' />
                <link rel='manifest' href='/manifest.json' />
                <link rel='mask-icon' href='/icons/safari-pinned-tab.svg' color='#5bbad5' />
                <link rel='shortcut icon' href='/favicon.ico' />
                <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500' />
                <meta name='twitter:card' content='summary' />
                <meta name='twitter:url' content='https://rushers.io' />
                <meta name='twitter:title' content={metaTitle} />
                <meta name='twitter:description' content={metaDescription} />
                <meta name='twitter:image' content='https://rushers.io/icons/android-chrome-192x192.png' />
                <meta name='twitter:creator' content='@DavidWShadow' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content={metaTitle} />
                <meta property='og:description' content={metaDescription} />
                <meta property='og:site_name' content={metaTitle} />
                <meta property='og:url' content='https://rushers.io' />
                <meta property='og:image' content='https://yourdomain.com/icons/apple-touch-icon.png' />
                {/*
                <link rel='apple-touch-startup-image' href='/images/apple_splash_2048.png' sizes='2048x2732' />
                <link rel='apple-touch-startup-image' href='/images/apple_splash_1668.png' sizes='1668x2224' />
                <link rel='apple-touch-startup-image' href='/images/apple_splash_1536.png' sizes='1536x2048' />
                <link rel='apple-touch-startup-image' href='/images/apple_splash_1125.png' sizes='1125x2436' />
                <link rel='apple-touch-startup-image' href='/images/apple_splash_1242.png' sizes='1242x2208' />
                <link rel='apple-touch-startup-image' href='/images/apple_splash_750.png' sizes='750x1334' />
                <link rel='apple-touch-startup-image' href='/images/apple_splash_640.png' sizes='640x1136' />
                */}
            </Head>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <AuthProvider>
                        <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
                            <SettingsConsumer>
                                {({ settings }) => {
                                    return (
                                        <ThemeComponent settings={settings}>
                                            <Guard authGuard={authGuard} guestGuard={guestGuard}>
                                                <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard} authGuard={authGuard}>
                                                    <ErrorBoundary router={routers}>{getLayout(<Component {...pageProps} />)}</ErrorBoundary>
                                                </AclGuard>
                                            </Guard>
                                            <ReactHotToast>
                                                <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                                            </ReactHotToast>
                                        </ThemeComponent>
                                    )
                                }}
                            </SettingsConsumer>
                        </SettingsProvider>
                    </AuthProvider>
                </PersistGate>
            </Provider>
        </CacheProvider>
    )
}

export default App
