// ** Next Import
import Link from 'next/link'

// ** MUI Imports
import Box from '@mui/material/Box'
import { Theme, useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useContext } from 'react'
import { SettingsContext } from '../../../../context/settingsContext'

const FooterContent = () => {
    // ** Var
    const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
    const { version } = require('../../../../../../package.json')
    const setting = useContext(SettingsContext)
    const { settings } = setting
    const Theme = useTheme()
    const LinkStyled = styled(Link)(({ theme }) => {
        return {
            textDecoration: 'none',
            color: settings.mode === 'semi-dark' ? theme.palette.primary.main : '#fdd835',
            fontWeight: 'bold'
        }
    })

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ mr: 2 }}>
                {`Rushers © ${new Date().getFullYear()} Made with `}
                <Box component='span' sx={{ color: 'error.main' }}>
                    ❤️
                </Box>
                {` by `}
                <LinkStyled target='_blank' href='https://rushers.io/'>
                    IT Department
                </LinkStyled>
            </Typography>
            {hidden ? null : (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', '& :not(:last-child)': { mr: 4 } }}>
                    {/*TODO:replace with mail support*/}
                    <LinkStyled target='_blank' href='/support'>
                        Support
                    </LinkStyled>
                    {version && (
                        <Typography
                            variant='body2'
                            sx={{ color: settings.mode === 'semi-dark' ? Theme.palette.primary.main : '#fdd835', textAlign: 'center', textDecoration: 'none', fontWeight: 'bold' }}
                        >
                            {'v.' + version}
                        </Typography>
                    )}
                </Box>
            )}
        </Box>
    )
}

export default FooterContent
