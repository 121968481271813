// ** Type Import
import { OwnerStateThemeType } from './'

// ** Util Import
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'

const Backdrop = () => {
    return {
        MuiBackdrop: {
            styleOverrides: {
                root: ({ theme }: OwnerStateThemeType) => ({
                    backgroundColor: theme.palette.mode === 'light' ? `rgba(${theme.palette.customColors.main}, 0.5)` : hexToRGBA('#13251f', 0.87)
                }),
                invisible: {
                    backgroundColor: 'transparent'
                }
            }
        }
    }
}

export default Backdrop
