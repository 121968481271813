import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import store from '../store'
import { deleteCredentials } from '../store/slices/auth'
import { deleteCookie, getCookies } from 'cookies-next'
import { deletePermissions } from '../store/slices/permissions'

const apiClient = axios.create({
    timeout: 15000,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})
apiClient.interceptors.request.use(
    async (config: AxiosRequestConfig | any) => {
        return {
            ...config
        }
    },
    error => Promise.reject(error)
)

apiClient.interceptors.response.use(
    async (response: AxiosResponse) => {
        const cookies = getCookies()
        const isServerSide = typeof window === 'undefined'
        if (isServerSide) {
            //console.log(cookies, 'ssr', response)
        }

        return response
    },
    async (error: AxiosError) => {
        if (error.message === 'Network Error') {
            //TODO: Manage NetworkError
            console.log('NetworkError')
        }

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        switch (error.status) {
            case 401:
                console.log('error_response_401', JSON.stringify(error))
                deleteCookie('XSRF-TOKEN')
                store.dispatch(deleteCredentials())
                store.dispatch(deletePermissions())
                break
            case 419:
                console.log('error_response_419', JSON.stringify(error))
                deleteCookie('XSRF-TOKEN')
                store.dispatch(deleteCredentials())
                store.dispatch(deletePermissions())
            default:
                break
        }

        const isClient = typeof window !== 'undefined'
        if (isClient) {
            return Promise.reject(error)
        } else {
            return Promise.reject(error)
        }
    }
)

export default apiClient
