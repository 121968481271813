// ** MUI Imports
import { styled, useTheme } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar'
import MuiToolbar, { ToolbarProps } from '@mui/material/Toolbar'

// ** Type Import
import { LayoutProps } from 'src/@core/layouts/types'

// ** Util Import
import { useContext } from 'react'
import { SettingsContext } from '../../../../context/settingsContext'

interface Props {
    hidden: LayoutProps['hidden']
    toggleNavVisibility: () => void
    settings: LayoutProps['settings']
    saveSettings: LayoutProps['saveSettings']
    appBarContent: NonNullable<LayoutProps['verticalLayoutProps']['appBar']>['content']
    appBarProps: NonNullable<LayoutProps['verticalLayoutProps']['appBar']>['componentProps']
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
    transition: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 6),
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    minHeight: theme.mixins.toolbar.minHeight,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
}))

const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
    width: '100%',
    padding: '0 !important',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
    transition: 'padding .25s ease-in-out, box-shadow .25s ease-in-out, background-color .25s ease-in-out'
}))

const LayoutAppBar = (props: Props) => {
    // ** Props
    const { settings, appBarProps, appBarContent: userAppBarContent } = props
    const settingsContextValue = useContext(SettingsContext)

    // ** Hooks
    const theme = useTheme()
    const scrollTrigger = useScrollTrigger({ threshold: 0, disableHysteresis: true })

    // ** Vars
    const { skin, appBar, appBarBlur, contentWidth } = settings

    const appBarFixedStyles = () => {
        return {
            px: `${theme.spacing(6)} !important`,
            boxShadow: skin === 'bordered' ? 0 : 0,
            ...(appBarBlur && { backdropFilter: 'none !important' }),
            backgroundColor: settingsContextValue.settings.mode === 'semi-dark' ? '#fff' : '#13251F',
            ...(skin === 'bordered' && { border: `1px solid ${theme.palette.divider}`, borderTopWidth: 0 })
        }
    }

    if (appBar === 'hidden') {
        return null
    }

    let userAppBarStyle = {}
    if (appBarProps && appBarProps.sx) {
        userAppBarStyle = appBarProps.sx
    }
    const userAppBarProps = Object.assign({}, appBarProps)
    delete userAppBarProps.sx

    return (
        <AppBar
            elevation={0}
            color='default'
            className='layout-navbar'
            sx={{ ...userAppBarStyle, backdropFilter: 'none !important' }}
            style={{
                boxShadow: '0px 2px 10px 0px rgba(76, 78, 100, 0.22)',
                borderRadius: 0,
                padding: 0,
                transition: 'none !important',
                backdropFilter: 'none !important',
                backgroundColor: settingsContextValue.settings.mode === 'semi-dark' ? '#fff' : '#13251F'
            }}
            position={appBar === 'fixed' ? 'sticky' : 'static'}
            {...userAppBarProps}
        >
            <Toolbar
                className='navbar-content-container'
                sx={{
                    padding: '0px 1.5rem !important',
                    transition: 'none !important',
                    backdropFilter: 'none !important',
                    borderRadius: 0,
                    ...(appBar === 'fixed' && scrollTrigger && { ...appBarFixedStyles() }),
                    ...(contentWidth === 'boxed' && {
                        '@media (min-width:1440px)': { maxWidth: `calc(1440px - ${theme.spacing(6)} * 2)` }
                    })
                }}
            >
                {(userAppBarContent && userAppBarContent(props)) || null}
            </Toolbar>
        </AppBar>
    )
}

export default LayoutAppBar
