// ** Type import
import { VerticalNavItemsType } from 'src/@core/layouts/types'

const navigation = (): VerticalNavItemsType => {
    return [
        {
            title: 'Dashboard',
            icon: 'mdi:home-variant-outline',
            auth: false,
            path: '/home'
        },
        {
            title: 'Ordini',
            icon: 'material-symbols:shopping-basket-outline',
            auth: true,
            action: 'data_view',
            subject: 'orders',
            children: [
                { title: 'Ordini', path: '/orders', subject: 'orders', action: 'data_view' },
                { title: 'Stati Ordine', path: '/order-states', subject: 'orders', action: 'data_view' },
                { title: 'Solleciti', path: '/order-reminders', subject: 'orders', action: 'data_view' },
                { title: 'Tipologie Ordini', path: '/order-types', subject: 'orders', action: 'data_view' },
                { title: 'Tipologie Incentivi', path: '/bonus-types', subject: 'orders', action: 'data_view' }
            ]
        },
        {
            title: 'Turni',
            icon: 'mdi:calendar-clock-outline',
            subject: 'shifts',
            action: 'data_view',
            auth: true,
            children: [
                { title: 'Turni', path: '/shifts', subject: 'shifts', action: 'data_view' },
                { title: 'Overview', path: '/overview-rushers', subject: 'shifts', action: 'data_view' }
            ]
        },
        {
            title: 'Target Turni',
            icon: 'mdi:target-account',
            subject: 'shifts_targets',
            action: 'data_view',
            auth: true,
            children: [
                { title: 'Target Turni', path: '/targets-shifts', subject: 'shifts_targets', action: 'data_view' },
                { title: 'Periodi', path: '/periods-shifts', subject: 'shifts_targets', action: 'data_view' },
                { title: 'Percentuali', path: '/percentages-shifts', subject: 'shifts_targets', action: 'data_view' }
            ]
        },
        {
            title: 'Rushers',
            path: '/rushers',
            icon: 'mdi:human-dolly',
            subject: 'rushers',
            action: 'data_view',
            auth: true,
            children: [
                { title: 'Rushers', path: '/rushers', subject: 'rushers', action: 'data_view' },
                { title: 'Veicoli', path: '/vehicles', subject: 'rushers', action: 'data_view' }
            ]
        },
        {
            title: 'Zone',
            path: '/areas',
            icon: 'mdi:map-outline',
            subject: 'areas',
            action: 'data_view',
            auth: true,
            children: [
                { title: 'Zone', path: '/areas', subject: 'areas', action: 'data_view' },
                { title: 'Gruppi Zone', path: '/groups-areas', subject: 'areas', action: 'data_view' }
            ]
        },
        {
            title: 'Ricompense',
            path: '/rewards',
            icon: 'mdi:trophy-outline',
            subject: 'rewards',
            action: 'data_view',
            auth: true,
            children: [
                { title: 'Ricompense', path: '/rewards', subject: 'rewards', action: 'data_view' },
                { title: 'Categorie Ricompense', path: '/category-rewards', subject: 'rewards', action: 'data_view' },
                { title: 'Obiettivi', path: '/goals', subject: 'rewards', action: 'data_view' },
                { title: 'Tipologie Obiettivi', path: '/types-goals', subject: 'rewards', action: 'data_view' },
                { title: 'Overview', path: '/overview-goals', subject: 'rewards', action: 'data_view' }
            ]
        },
        {
            title: 'Pagamenti',
            icon: 'mdi:credit-card-outline',
            action: 'data_view',
            subject: 'accounting',
            auth: true,
            children: [
                { title: 'Aziende', path: '/payments/page-companies-payments', subject: 'accounting', action: 'data_view' },
                { title: 'Rushers', path: '/payments/page-rushers-payments', subject: 'accounting', action: 'data_view' }
            ]
        },
        {
            title: 'Notifiche',
            path: '/notifications',
            icon: 'mdi:notifications-none',
            subject: 'notifications',
            action: 'data_view',
            auth: true,
            children: [
                { title: 'Notifiche', path: '/notifications', subject: 'notifications', action: 'data_view' },
                { title: 'Azioni Notifiche', path: '/notification-actions', subject: 'notifications', action: 'data_view' }
            ]
        },
        {
            title: 'Strumenti',
            path: '/tools',
            auth: true,
            action: 'data_view',
            subject: 'areas',
            icon: 'mdi:toolbox-outline',
            children: [
                {
                    title: 'Disponibilità Rushers',
                    path: '/tools/availability-rushers',
                    subject: 'areas',
                    action: 'data_view'
                },
                { title: 'Overview Zone', path: '/tools/overview-map', subject: 'areas', action: 'data_view' }
            ]
        },

        {
            title: 'Aziende',
            auth: true,
            action: 'data_view',
            subject: 'companies',
            icon: 'mdi:company',
            children: [
                { title: 'Aziende', path: '/companies', subject: 'companies', action: 'data_view' },
                { title: 'Utenti', path: '/users', subject: 'companies', action: 'data_view' }
            ]
        },
        {
            sectionTitle: 'Administration',
            auth: true
        },
        {
            path: '/support',
            auth: false,
            title: 'Supporto',
            icon: 'mdi:face-agent'
        },
        {
            title: 'Utenti e Ruoli',
            icon: 'material-symbols:admin-panel-settings-outline',
            action: 'data_view',
            subject: 'administration',
            auth: true,
            children: [
                { title: 'Amministratori', path: '/admins', subject: 'administration', action: 'data_view' },
                { title: 'Ruoli', path: '/roles', subject: 'administration', action: 'data_view' },
                { title: 'Moduli', path: '/modules', subject: 'administration', action: 'data_view' }
            ]
        },
        {
            title: 'Globalizzazione',
            action: 'data_view',
            subject: 'globalization',
            icon: 'mdi:translate',
            auth: true,
            children: [
                { title: 'Lingue', path: '/languages', subject: 'globalization', action: 'data_view' },
                { title: 'Etichette', path: '/labels', subject: 'globalization', action: 'data_view' },
                { title: 'Valute', path: '/currencies', subject: 'globalization', action: 'data_view' }
            ]
        },
        {
            title: 'Impostazioni',
            action: 'data_view',
            subject: 'settings',
            icon: 'mdi:gear-outline',
            auth: true,
            children: [
                { title: 'Webhooks', subject: 'settings', path: '/webhook-events', action: 'data_view' },
                { title: 'Conti Bancari', subject: 'accounting', path: '/bank-accounts', action: 'data_view' },
                { title: 'Impostazioni', subject: 'settings', path: '/settings/', action: 'data_view' }
            ]
        },

        {
            path: '/acl',
            action: 'read',
            subject: 'acl-page',
            title: 'Access Control',
            icon: 'mdi:shield-outline'
        }
    ]
}

export default navigation
