import { AbilityBuilder, Ability } from '@casl/ability'
import store from '../store'

export type Subjects = string
export type Actions = 'manage' | 'data_create' | 'data_view' | 'data_edit' | 'data_delete'

export type AppAbility = Ability<[Actions, Subjects]> | undefined

export const AppAbility = Ability as any
export type ACLObj = {
    action: Actions
    subject: string
}

/**
 * Please define your own Ability rules according to your app requirements.
 * We have just shown Admin and Client rules for demo purpose where
 * admin can manage everything and client can just visit ACL page
 */
const defineRulesFor = (role: number, subject: string) => {
    const { can, rules } = new AbilityBuilder(AppAbility)
    const permissionsData = store.getState().permissions.data

    //TODO:create this after modules fetch

    if (role && permissionsData) {
        //Add abilities for modules
        Object.entries(permissionsData).forEach(([moduleName, permissions]) => {
            return can(permissions as string[], moduleName)
        })
        can(['data_view'], 'home')
    } else if (!role) {
        can(['data_view'], 'acl-page')
    } else {
        can(['data_view', 'data_create', 'data_edit', 'data_delete'], subject)
    }

    return rules
}

export const buildAbilityFor = (role: number, subject: string): AppAbility => {
    return new AppAbility(defineRulesFor(role, subject), {
        // https://casl.js.org/v5/en/guide/subject-type-detection
        // @ts-ignore
        detectSubjectType: object => object!.type
    })
}

export const defaultACLObj: ACLObj = {
    action: 'data_view',
    subject: 'all'
}

export default defineRulesFor
