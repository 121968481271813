const typography = {
    fontFamily: [
        'Inter',
        'sans-serif',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
    ].join(','),
    h1: {
        fontWeight: 500,
        letterSpacing: '-1.5px'
    },
    h2: {
        fontWeight: 500,
        letterSpacing: '-0.5px'
    },
    h3: {
        fontWeight: 500,
        letterSpacing: 0
    },
    h4: {
        fontWeight: 500,
        letterSpacing: '0.25px'
    },
    h5: {
        fontWeight: 500,
        letterSpacing: 0
    },
    h6: {
        letterSpacing: '0.15px'
    },
    subtitle1: {
        letterSpacing: '0.15px'
    },
    subtitle2: {
        letterSpacing: '0.1px'
    },
    body1: {
        letterSpacing: '0.15px'
    },
    body2: {
        lineHeight: 1.429,
        letterSpacing: '0.15px'
    },
    button: {
        letterSpacing: '0.4px'
    },
    caption: {
        lineHeight: 1.25,
        letterSpacing: '0.4px'
    },
    overline: {
        letterSpacing: '1px'
    }
}

export default typography
